.row {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 10px;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.root button,
.root a {
  padding: 16px;
}

.selectSmall {
  flex: 0 0 56px;
}

.selectSmall :global(.rc-select .rc-select-selector) {
  padding-left: 8px;
  padding-right: 8px;
}

.selectSmall :global(.rc-select-arrow) {
  display: none;
}

.selectButtonIcon {
  padding: 5px;
  color: var(--grey-2);
}

.selectButtonIcon svg {
  width: 27px;
  height: 27px;
}

.selectButton :global(.select__menu) {
  right: 0;
  min-width: 200px;
}

@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
  }

  .select {
    flex-basis: 30%;
  }

  .root > div {
    flex-grow: 1;
  }

  .search {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .root button,
  .root a {
    width: 100%;
  }

  .select {
    width: 100%;
  }

  .selectButton {
    width: 100%;
  }
}
