.root {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.item {
  text-align: center;
  padding: 15px;
  border-radius: var(--border-radius-l);
  background: var(--color-white-primary);
  opacity: 0.65;
}

.item.active {
  opacity: 1;
}

.itemValue {
  font-size: 20px;
  font-weight: 600;
}

.itemText {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.14;
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }
}
