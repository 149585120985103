.column {
  padding: 15px 6px;
  background: var(--grey-5);
  border-radius: var(--border-radius-l);
  transition: all var(--transition);
  overflow: hidden;
}

.column.hover {
  box-shadow: inset 0 0 0 1px #79BE70;
  background: #E4F0E4;
}

.title {
  margin-left: 15px;
  margin-bottom: 15px;
  font-weight: 600;
}

.titleCount {
  margin-left: 6px;
  color: var(--grey-2);
}

.tasks {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tasksItem {
  cursor: pointer;
}

.notFound {
  padding: 35px 0;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--grey-2);
}
