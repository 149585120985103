.root {
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
  gap: 5px;
}

.item {
  text-align: center;
}

.itemValue {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: var(--border-radius-l);
  background: var(--grey-4, #D3D7DB);
  color: var(--color-white-primary);
  box-shadow: var(--box-shadow-c);
  font-size: 20px;
  font-weight: 600;
}

.itemText {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.42;
}
