.cellDate {
  width: 17%;
  font-weight: 500;
}

.cellName {
  width: 35%;
}

.cellCompany {
  width: 28%;
}

.cellEmail {
  width: 20%;
  word-wrap: break-word;
}

.cellDate,
.cellName,
.cellCompany,
.cellEmail {
  font-size: 0.875rem;
  line-height: 1;
  cursor: pointer;
}

.buttons:hover {
  box-shadow: none;
}

.buttons.buttons td {
  padding: 10px 0;
  border: none;
  background: none;
  text-align: right;
}

.button {
  min-width: 200px;
}