.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.dropContainer {
  margin-bottom: -14px;
  transition: var(--transition-fast);
}

.dragging {
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
}

.id {
  width: 10%;
  cursor: pointer;
}

.type {
  width: 22%;
  cursor: pointer;
}

.name {
  width: 55%;
  cursor: pointer;
}

.groups {
  width: 13%;
  cursor: pointer;
}

.button {
  width: 40px;
}

.remove:hover {
  color: var(--color-red-primary);
}

@media (max-width: 800px) {
  .button > * {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
