.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 12px;
  left: 18px;
  bottom: 42px;
  width: 60px;
  padding: 7px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-a);
  transition: all var(--transition);
  overflow: hidden;
  z-index: 100;
}

.root.open {
  width: 260px;
}

.root.open .item {
  width: 246px;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 77px;
  left: 88px;
  width: 30px;
  height: 30px;
  border: none;
  margin: 0;
  padding: 0;
  color: var(--grey-3);
  border-radius: var(--border-radius-xs);
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-a);
  line-height: 0;
  z-index: 110;
}

.toggle:hover {
  color: var(--color-black-primary);
}

.toggle.open {
  left: 263px;
}

.toggleIcon {
  transition: all var(--transition-fast);
}

.toggle.open .toggleIcon {
  transform: scaleX(-1);
}

.list {
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
  scrollbar-width: none;
}

.list::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}

.list::-webkit-scrollbar-track {
  background: transparent;
}

.item {
  position: relative;
  margin: 0 0 10px;
  padding: 0;
  border-radius: var(--border-radius-l);
  border: 1px solid transparent;
}

.root:not(.open) .item.open {
  border: 1px solid var(--grey-3);
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 9px 5px;
  height: 54px;
  width: 100%;
  background: none;
  border: none;
  border-radius: var(--border-radius-l);
  box-shadow: none;
  font-size: 1rem;
  line-height: 1.12;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  gap: 11px;
}

.link:hover,
.link:focus,
.link.open {
  background: var(--grey-7);
}

.link.active .icon {
  color: var(--primary-color);
}

.link.open .arrow {
  transform: scaleY(-1);
}

.link.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.icon {
  flex-shrink: 0;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-3);
  transition: color var(--transition);
  line-height: 0;
}

.arrow {
  margin-right: 6px;
  width: 11px;
  margin-left: auto;
  color: var(--grey-3);
  line-height: 0;
  transition: transform var(--transition);
}

.arrow.absolute {
  position: absolute;
  left: 16px;
  bottom: 4px;
}

.arrow svg {
  width: 100%;
}

.logout {
  margin-top: 10px;
  background: var(--grey-6);
}

.logout .icon {
  color: var(--color-black-primary);
}

.logout span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.logoutName {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.logoutEmail {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: var(--grey-1);
}

.childs {
  list-style: none;
  margin: 0 4px;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all var(--transition);
}

.childs.open {
  max-height: 300px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.childItem {
  margin: 0;
  padding: 0;
}

.childItem:not(:last-child) {
  margin-bottom: 5px;
}

.childLink {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 1px;
  border-radius: var(--border-radius-s);
  color: var(--grey-1);
  gap: 12px;
}

.childLink:hover,
.childLink:focus {
  background: var(--grey-7);
}

.childLink.active {
  color: var(--grey-0);
}

.childLink.active .childIcon {
  color: var(--primary-color);
}

.childLink.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.childIcon {
  flex-shrink: 0;
  width: 34px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: var(--grey-3);
}

.root.open .childLink {
  height: 55px;
  padding: 4px 15px;
}

.root.open .childs {
  margin-left: 8px;
  margin-right: 8px;
}

.root.open .link.open {
  background: none;
}

.root.open .link.open:hover,
.root.open .link.open:focus {
  background: var(--grey-7);
}

.root.open .link.open .arrow {
  color: var(--primary-color);
}

.bottom {
  margin-top: auto;
}

.logoContainer {
  margin: 7px 5px 32px;
}

.logo {
  gap: 16px;
  margin-right: 36px;
}

.logoText {
  margin-left: auto;
  margin-right: auto;
}

.logoText path {
  fill: var(--color-black-primary);
}

div.tooltip:global(-content.tooltip-content) {
  min-width: 244px;
  padding: 10px;
  border: none;
  border-radius: var(--border-radius-l);
  font-size: 1rem;
  color: var(--color-black-primary);
  background: var(--color-white-primary);
}

.menuButton {
  display: none;
}

@media (max-width: 575px) {
  .root {
    left: -100px;
  }

  .root.open {
    left: 10px;
  }

  .menuButton {
    display: block;
  }

  .toggle {
    display: none;
  }
}
