.title {
  margin: 0 0 12px;
  font-size: 2.25rem;
  line-height: 1.32;
}

.subtitle {
  font-weight: 600;
  color: var(--grey-2);
}

.trial {
  margin-bottom: 32px;
  font-size: 1.25rem;
  font-weight: 600;
}

.registeredEmail {
  margin-bottom: 48px;
}

.registeredChange {
  display: inline-block;
  margin-top: 12px;
  color: var(--primary-color);
}

.promocode {
  margin-top: 34px;
  margin-bottom: 52px;
}

.promocodeTitle {
  margin-bottom: 32px;
  font-size: 1.5rem;
  font-weight: 600;
}

.promocodeInputs input {
  text-align: center;
}

.promocodeNotice {
  margin-top: 20px;
  color: var(--grey-2);
}

.agreement {
  margin-top: 32px;
  color: var(--grey-2);
}

.agreement a {
  color: var(--primary-color-dark);
}

.form {
  width: 100%;
  max-width: 558px;
  margin-top: 48px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.inputs input {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 28px auto 0;
  max-width: 422px;
  gap: 32px;
}

.errors {
  min-height: 48px;
  margin-top: 20px;
}

.error {
  color: var(--error-color);
}

.recover {
  max-width: 540px;
  font-weight: 600;
}

.inputTitle {
  max-width: 540px;
  font-weight: 600;
}

.successPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.successPage::after {
  content: '';
  display: block;
  height: 100px;
}

@media (max-width: 575px) {
  .trial {
    margin-bottom: 24px;
  }

  .form {
    margin-top: 24px;
  }
}