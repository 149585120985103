.buttons {
  display: flex;
  gap: 10px;
  margin: 0 0 58px;
}

.controls {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  margin-right: -5px;
  gap: 12px;
  overflow-y: auto;
}

.controls > div {
  flex-shrink: 0;
}

.controlsButtons {
  margin: 12px 20px 36px;
}

.placeholderButton {
  height: 60px;
  border-radius: var(--border-radius-m);
}

.iconAdd {
  color: var(--primary-color);
}

@media (max-width: 800px) {
  .root {
    display: flex;
    flex-direction: column-reverse;
  }

  .buttons {
    margin: 46px 0 10px;
  }
}
