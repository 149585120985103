.root {
  height: 100%;
  padding: 25px;
  border-radius: var(--border-radius-l);
  border: 1px solid var(--grey-3);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  gap: 10px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.buttons {
  display: flex;
  gap: 5px;
}

.buttons a,
.buttons button {
  padding: 0;
}
