.root {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.total {
  width: calc(32% - 9px);
}

.prolongation {
  width: calc(68% - 9px);
}

.conversion {
  width: calc(32% - 12px);
}

.week {
  width: calc(34% - 12px);
}

.month {
  width: calc(34% - 12px);
}

@media (max-width: 575px) {
  .column {
    width: 100%;
  }
}
