.title {
  margin-bottom: 12px;
  font-size: 1.25rem;
  font-weight: 700;
}

.fields > div {
  margin-bottom: 16px;
}

.buttons {
  margin-top: 36px;
  justify-content: flex-end;
}

.button {
  min-width: 240px;
}

.checkbox {
  display: inline-block;
}

.checkbox > label {
  padding: 8px 24px;
  justify-content: flex-start;
  border-radius: var(--border-radius-m);
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-b);
}
