.head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 15px;
}

.creator {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  gap: 15px;
}

.select :global(.rc-select-selector) {
  background: var(--grey-6);
  height: 48px;
}

.select:global(.rc-select-open .rc-select-selector) {
  background: var(--color-white-primary);
}

.select :global(.rc-select-selection-placeholder) {
  color: var(--color-black-primary);
}

.select.position :global(.rc-select-selector) {
  height: 36px;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: var(--border-radius-m);
}

.select.position:global(.rc-select-open .rc-select-selector) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selectContainer.executor {
  flex: 1 1 42%;
}

.selectContainer.priority {
  flex: 1 1 38%;
}

.selectContainer.position {
  flex: 1 1 20%;
}

.positionContainer {
  flex: 0 0 184px;
}

.option {
  display: flex;
  align-items: center;
  gap: 12px;
}

.forSelf {
  font-size: 12px;
  color: var(--grey-1);
}

.historyHead {
  display: flex;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--grey-3);
  gap: 15px;
}

.comment {
  background: none;
  border-color: var(--grey-3);
}

.comment:hover {
  border-color: var(--grey-2);
}

.comment:focus {
  border-color: var(--primary-color);
}

.time {
  font-size: 0.875rem;
  line-height: 1.2;
}

.time.DURATION_GOOD {
  color: #79BE70;
}

.time.DURATION_NORMAL {
 color: #969C9E;
}

.time.DURATION_BAD {
  color: #FFA88C;
}

.time.DURATION_OVERDUE {
  color: #E26565;
}

.remove:hover {
  color: var(--error-color);
}

.fileName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.signInButton {
  flex-shrink: 0;
  padding-left: 25px;
  padding-right: 25px;
}

.downloadButton {
  flex-shrink: 0;
  font-weight: 400;
  text-decoration: underline;
}

.sendButton {
  min-width: 240px;
}

.historyList {
  height: 285px;
  margin-top: 15px;
  padding: 25px;
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-l);
  overflow-y: auto;
}

.historyItem {
  font-size: 14px;
  color: var(--grey-1);
}

.historyItem:not(:last-child) {
  margin-bottom: 15px;
}

.historyItem.asComment {
  padding: 7px;
  font-weight: 600;
  color: var(--color-black-primary);
  background: var(--grey-5);
  border-radius: var(--border-radius-xs);
}

.downloadButtonIcon {
  color: var(--primary-color);
}