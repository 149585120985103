.row {
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
  gap: 18px;
}

.date {
  flex-shrink: 0;
  color: var(--grey-2);
}

.footer {
  display: flex;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  gap: 18px;
}

.footerValue {
  text-align: right;
  font-weight: 400;
}
