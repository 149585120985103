.value {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.28;
}

.valueText {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
}

.text {
  margin-top: 5px;
  color: var(--grey-2, #969C9E);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.11;
}
