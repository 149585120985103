.root {
  position: relative;
}

.checkbox > label {
  justify-content: flex-start;
}

.title {
  margin-bottom: 8px;
  font-size: 1.15rem;
  font-weight: 700;
}

.item {
  margin-bottom: 20px;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white-primary);
  opacity: 0.7;
  z-index: 100;
}