.root {
  display: flex;
  gap: 25px;
}

.col {
  flex: 1;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  font-size: 14px;
  line-height: 1.4;
  gap: 10px;
}

.row:not(:last-child) {
  margin-bottom: 5px;
}

.rowButton {
  margin-top: 10px;
  text-align: center;
}

.value {
  text-align: right;
}

.title {
  margin-top: -45px;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
}

.codes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 18px;
}

.code {
  padding: 6px 40px;
  color: var(--color-green-primary);
  background: var(--grey-6);
  border-radius: var(--border-radius-l);
  text-align: center;
}

.button {
  min-height: 0;
  font-size: 15px;
  font-weight: 500;
  padding: 6px 16px;
}

@media (max-width: 1024px) {
  .codes {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .title {
    margin-top: 0;
  }
}
