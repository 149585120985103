.chart {
  width: 100%;
  height: 253px;
}

.footer {
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  gap: 47px;
}

.footerValue {
  text-align: right;
  color: var(--primary-color);
}
