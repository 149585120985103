.items {
  margin: 0 -25px;
}

.row {
  display: flex;
  align-items: center;
  padding: 7px 25px;
  font-size: 14px;
  background: var(--grey-6);
  gap: 10px;
}

.row:nth-child(2n) {
  background: none;
}

.date {
  flex-shrink: 0;
  color: var(--grey-2);
  font-size: 12px;
}

.link {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background: none;
  border: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  gap: 10px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}