.item {
  display: flex;
  padding: 5px;
  overflow: hidden;
  gap: 5px;
}

.number {
  flex-shrink: 0;
  min-width: 31px;
}

.link {
  flex-shrink: 1;
  word-wrap: break-word;
  overflow: hidden;
}

.link:hover {
  text-decoration: underline;
}
