.root {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 318px;
  background: var(--grey-6);
  border: 2px dashed var(--grey-3);
  border-radius: var(--border-radius-l);
  text-align: center;
  transition: var(--transition);
}

.title {
  font-weight: 400;
  color: var(--color-black-primary);
}

.placeholder {
  font-weight: 600;
  color: var(--grey-2);
}

.buttons {
  margin-top: 30px;
}

.moreButtons {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.files {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  gap: 10px;
}

.fileIcon {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  width: 226px;
}

.remove {
  position: absolute;
  top: 16px;
  right: 16px;
  flex-shrink: 0;
}

.removeIcon {
  width: 16px;
  height: 16px;
}

.multiple {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-right: 10px;
  margin-right: -10px;
}

.multiple .file {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-m);
}

.multiple .fileIcon {
  width: 30px;
  height: 30px;
}

.multiple .fileName {
  flex: 1;
  margin-right: 20px;
  margin-left: 20px;
  text-align: left;
}

@media (max-width: 800px) {
  .title {
    font-size: 1.1rem;
  }
}

@media (max-width: 575px) {
  .button {
    width: 100%;
  }
}
