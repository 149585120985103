.items {
  margin: 0 -25px;
}

.row {
  display: flex;
  padding: 7px 25px;
  font-size: 14px;
  background: var(--grey-6);
  gap: 16px;
}

.row:nth-child(2n) {
  background: none;
}

.row:hover .actions {
  display: block;
}

.left {
  flex-shrink: 0;
  width: 135px;
  color: var(--grey-2);
  font-size: 13px;
}

.link {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background: none;
  border: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  gap: 10px;
}

.content {
  flex-grow: 1;
}

.actions {
  flex-shrink: 0;
  display: none;
}

.remove:hover {
  color: var(--error-color);
}

.removeIcon {
  width: 20px;
  height: 20px;
}