.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.items {
  display: flex;
  align-items: center;
  gap: 18px;
}

.item {
  padding: 8px 25px;
  font-size: 14px;
  line-height: 1.14;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
}

.itemValue {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

@media (max-width: 1024px) {
  .root {
    align-items: flex-start;
    flex-direction: column;
  }

  .title {
    margin-bottom: 25px;
  }
}

@media (max-width: 800px) {
  .items {
    flex-wrap: wrap;
  }

  .item {
    width: calc(50% - 9px);
  }
}

@media (max-width: 575px) {
  .item {
    width: 100%;
  }
}
