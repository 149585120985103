.changeMonth {
  margin: 0px 20px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  gap: 10px;
}

.weekContainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 12px;
}

.weekName {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
  text-align: center;
  cursor: default;
}

.day {
  padding: 5px;
  height: 30px;
  width: 66px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 1.42;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #C6CBCD);
  cursor: default;
}

.dayCount {
  margin-left: 6px;
  font-weight: 600;
}

.day:hover {
  background: var(--grey-5);
}

.inactiveDay {
  color: var(--grey-3);
}

.select {
  display: inline-block;
  min-width: 200px;
}

.select :global(div.select__value-container) {
  padding: 0 20px;
}

.select :global(div.select__control) {
  min-height: 32px;
  height: 32px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  border-radius: var(--border-radius-xs);
}

.select :global(div.select__dropdown-indicator) {
  padding: 0 10px;
}

.select :global(div.select__menu) {
  margin-top: 4px;
  border-radius: var(--border-radius-xs);
}

.select :global(div.select__option) {
  height: 32px;
}

.button {
  padding: 8px 5px;
  border-radius: 6px;
  background: var(--grey-5, #DFE2E5);
}

.buttonIcon {
  width: 20px;
  height: 14px;
}

@media (max-width: 1280px) {
  .day {
    width: 56px;
  }

  .dayCount {
    margin-left: 2px;
  }
}

@media (max-width: 575px) {
  .day {
    width: auto;
    height: 52px;
    text-align: center;
  }

  .dayCount {
    display: block;
    margin-left: 0;
  }
}
