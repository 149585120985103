.root {
  display: flex;
  gap: 18px;
}

.item:not(:last-child) {
  padding-right: 18px;
  border-right: 1px solid var(--grey-3, #C6CBCD);
}

.itemValue {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.itemLabel {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
}

@media (max-width: 575px) {
  .items {
    flex-direction: column;
  }
}
