.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.content {
  position: relative;
}

.fio {
  width: 28%;
}

.position {
  width: 22%;
}

.group {
  width: 28%;
}

.buttons {
  width: 60px;
  line-height: 1;
}

.selectContainer {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: 12px;
}

.select {
  margin: -10px;
}

.select :global(.rc-select:not(.rc-select-open) .rc-select-selector) {
  background: none;
  border: 1px dashed var(--grey-3);
}

.select :global(.rc-select:not(.rc-select-open) .rc-select-selector:hover) {
  background: var(--grey-6);
  border: 1px solid var(--grey-6);
}

.select :global(.rc-select-selector) {
  height: 53px;
  font-size: 0.937rem;
  font-weight: 500;
}

.select :global(.rc-select-selection-placeholder) {
  color: var(--color-black-primary);
}

.remove {
  color: var(--grey-3);
}

.remove:hover {
  color: var(--error-color);
}

@media (max-width: 575px) {
  .select {
    margin: 6px 0 0;
  }

  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}