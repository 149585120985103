.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.id {
  width: 25%;
  cursor: pointer;
}

.name {
  width: 50%;
  cursor: pointer;
}

.users {
  width: 20%;
  cursor: pointer;
}

.duration {
  width: 12%;
  cursor: pointer;
}

.price {
  width: 20%;
  cursor: pointer;
}

.buttons {
  display: flex;
  width: 90px;
  gap: 16px;
}

.buttons button {
  line-height: 0;
}

.nameArrow {
  display: inline-block;
  line-height: 0;
  margin-left: 10px;
  transition: all var(--transition);
}

.nameArrow.open {
  transform: scaleY(-1);
}

.users svg {
  color: var(--primary-color);
}

.subitems:hover {
  box-shadow: none;
}

.subitemsCell.subitemsCell.subitemsCell {
  padding: 0 0 0 6%;
  border: none;
  background: none;
}

.subitems td {
  padding: 6px 16px;
  font-size: 0.9rem;
}

.subitemsHeadCell {
  padding-bottom: 6px;
}

.subitemsButtons:hover {
  box-shadow: none;
}

.subitemsButtons.subitemsButtons td {
  padding-left: 0;
  padding-right: 0;
  background: none;
  border: none;
  text-align: right;
}

.subheader {
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
}

.addButton {
  margin-left: auto;
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
