.value span {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.28;
}

.value {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
}

.text {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
}
