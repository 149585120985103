
.filters {
  flex-shrink: 0;
  width: 276px;
  padding: 18px;
  border-radius: var(--border-radius-l);
  border: 1px solid var(--grey-3);
}

.filtersItem {
  margin-bottom: 10px;
}

.filtersButton {
  margin-bottom: 10px;
}

.filtersButtonIcon {
  order: 1;
  margin-left: auto;
}

.filtersLink > span:last-child,
.filtersButton > span:last-child {
  margin: 0;
}

.filtersButton.withDate {
  color: var(--primary-color);
  text-align: left;
  gap: 10px;
}

.filtersLink {
  justify-content: flex-start;
  padding: 6px 7px;
  font-size: 15px;
  text-align: left;
  gap: 10px;
}

.filtersLink:hover {
  border-radius: var(--border-radius-xs);
  background: var(--grey-6);
}

.filtersLinkIcon {
  order: 1;
  margin-left: auto;
  width: 14px;
  height: 14px;
}

.filtersLinkIcon.open {
  transform: scaleY(-1);
}

.filtersSubitems {
  margin-top: 10px;
}

.filtersSubitems .filtersLink {
  padding-left: 25px;
}

.dateLabel {
  display: block;
  color: var(--color-black-primary);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.dateInput,
.modal :global(.forma-ui__field-container__icons) {
  --input-height: 36px;
  --input-radius: var(--border-radius-s);
  --input-icon-size: 16px;
}

.modal {
  display: flex;
  padding: 20px 0;
  gap: 26px;
}

.modalButton.modalButton {
  padding: 13px;
}

@media (min-width: 576px) {
  .modal {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .filters {
    width: 100%;
  }

  .modal {
    flex-direction: column;
  }
}