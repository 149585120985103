.root {
  display: flex;
  gap: 16px;
}

.button {
  width: 3.75rem;
  height: 3.75rem;
  text-transform: uppercase;
  border: 6px solid var(--color-white-primary);
}

.active,
.active:hover {
  background: var(--primary-color);
  color: var(--color-white-primary);
}
