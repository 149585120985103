.container {
  flex: 1;
}

.hidden {
  display: none;
}

.fullWidth {
  flex-basis: 100%;
  width: 100%;
}

.inputContainer {
  position: relative;
  display: flex;
}

.input {
  width: 100%;
}

.input::placeholder {
  color: var(--color-green-primary);
}

.label {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 14px;
  color: var(--grey-1);
}

.withIcon {
  padding-right: 56px;
}

.icons {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  right: 16px;
  height: 24px;
  line-height: 0;
  gap: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}

.button {
  cursor: pointer;
}

.errorText {
  display: block;
  margin-top: 9px;
  margin-bottom: -23px;
  color: var(--color-red-primary);
  font-size: 14px;
  line-height: 1;
}

.notice {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 10px;
  padding: 16px 32px;
  border: none;
  box-shadow: none;
  background: var(--grey-2);
  border-radius: var(--border-radius-l);
  color: var(--color-white-primary);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-15px);
  transition: all var(--transition);
  z-index: 10;
}

.tooltip.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.tooltip::after {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  right: 16px;
  height: 10px;
  width: 20px;
  background: url('./tooltip-arrow.svg');
}

@media (max-width: 575px) {
  .icons {
    display: none;
  }
}
