.table th {
  line-height: 1;
}

.table td {
  height: 44px;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 1.25;
}

.account {
  font-weight: 500;
}

.tariffHead {
  padding-left: 3%;
}

.tariff {
  width: 23%;
  min-width: 200px;
  padding-left: 3%;
  padding-top: 4px;
  padding-bottom: 4px;
}

.date {
  width: 100px;
  font-size: 0.875rem;
}

.count {
  width: 45px;
  text-align: center;
}

.buttons {
  width: 84px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.account a {
  color: var(--color-green-primary);
  text-decoration: underline;
  font-size: 13px;
  font-weight: 400;
}

.buttonSettings {
  margin: 0 -10px;
  padding: 16px 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.header {
  margin-bottom: 38px;
}

.headerInfo {
  display: flex;
  margin-bottom: 25px;
  gap: 20px;
}

.headerItem {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
}

.buttonIcon {
  width: 28px;
  height: 28px;
}

.accountInn {
  color: var(--grey-2);
  font-size: 0.875rem;
  font-weight: 400;
}

.tariffName {
  font-weight: 600;
  font-size: 0.875rem;
}

.tariffInfo {
  font-size: 0.875rem;
}

.checkedIcon {
  margin-left: auto;
  color: var(--color-green-primary);
}

.infoRow {
  font-size: 14px;
  color: var(--grey-0);
}

.infoTitle {
  margin-top: 16px;
  font-weight: 600;
}

.usersInput {
  border-radius: var(--border-radius-s);
  font-size: 14px;
  height: 36px;
  border-color: var(--grey-4);
}

@media (max-width: 1024px) and (min-width: 576px) {
  .tableWrap {
    width: 100%;
    overflow-x: auto;
  }

  .table {
    min-width: 800px;
    overflow-x: auto;
  }
}

@media (min-width: 576px) {
  .tariff {
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: 40px;
  }

  .modalContent {
    width: 500px;
  }
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
