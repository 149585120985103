.infoRow {
  font-size: 14px;
  color: var(--grey-0);
}

.infoTitle {
  margin-top: 16px;
  font-weight: 600;
}

.infoInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}

.infoInput {
  width: 100px;
  --input-height: 36px;
  --input-radius: var(--border-radius-s);
}
