.root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.item,
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 45px;
  margin: 0 5px;
  cursor: pointer;
  background: none;
  border: none;
  box-shadow: none;
  border-radius: var(--border-radius-xs);
  line-height: 0;
  transition: var(--transsition);
}

.arrow {
  background: var(--grey-6);
  line-height: 0;
}

.disabled {
  opacity: 0;
  visibility: hidden;
}

.item {
  font-family: var(--headings-font);
  font-size: 1rem;
  font-weight: 500;
  transition: var(--transition);
}

.item:hover {
  background: var(--grey-6);
}

.active {
  background: var(--grey-6);
}