.root {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.users {
  width: calc(69% - 9px);
}

.scoring {
  width: calc(31% - 9px);
}

.bill {
  width: calc(42% - 12px);
}

.requests {
  width: calc(37% - 12px);
}

.templates {
  width: calc(21% - 12px);
}

.ltv {
  width: calc(35% - 12px);
}

.tariff {
  width: calc(31% - 12px);
}

.dau {
  width: calc(17% - 12px);
}

.dealTime {
  width: calc(16% - 12px);
}

@media (max-width: 1280px) {
  .bill {
    width: calc(50% - 9px);
  }

  .requests {
    width: calc(50% - 9px);
  }

  .templates {
    width: calc(40% - 9px);
  }

  .ltv {
    width: calc(60% - 9px);
  }

  .tariff {
    width: calc(44% - 12px);
  }

  .dau {
    width: calc(28% - 12px);
  }

  .dealTime {
    width: calc(28% - 12px);
  }
}

@media (max-width: 1024px) {
  .users {
    width: 100%;
  }

  .scoring {
    width: 100%;
  }

  .bill {
    width: 100%;
  }

  .requests {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .tariff {
    width: 100%;
  }

  .dau {
    width: calc(50% - 9px);
  }

  .dealTime {
    width: calc(50% - 9px);
  }
}

@media (max-width: 575px) {
  .column {
    width: 100%;
  }
}
