.root {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 38px;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.button {
  padding-top: 16px;
  padding-bottom: 16px;
}

.select {
  width: 100%;
  max-width: 310px;
}

.select :global(.rc-select-selector) {
  height: 56px;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
  }

  .search {
    width: 100%;
  }

  .button,
  .select {
    flex: 1;
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .root button,
  .root a {
    width: 100%;
  }

  .select {
    margin-left: 0;
    max-width: 100%;
  }
}
