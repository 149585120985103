.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.root button,
.root a {
  padding: 16px;
}

.status {
  color: var(--grey-2, #969C9E);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
}

.totalValue {
  font-weight: 600;
}

.success {
  color: var(--success-color);
}

.error {
  color: var(--error-color);
}

.danger {
  color: var(--danger-color);
}


@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .root button,
  .root a {
    width: 100%;
  }
}
