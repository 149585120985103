.root > div > div {
  width: calc(33% - 20px);
}

@media (max-width: 1024px) {
  .root > div > div {
    width: calc(50% - 20px);
  }
}

@media (max-width: 575px) {
  .root > div > div {
    width: 100%;
  }
}