.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.id {
  width: 20%;
}

.lang {
  width: 5%;
}

.value {
  position: relative;
  width: 65%;
}

.buttons {
  width: 90px;
}

.buttons > div {
  display: flex;
  align-items: center;
  gap: 16px;
}

.remove:hover {
  color: var(--color-red-primary);
}

.removeIcon {
  width: 18px;
  height: 22px;
}

.input {
  padding-right: 50px;
}

.headerButtons {
  margin-top: 0;
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
