:root {
  --color-black-primary: #161616;
  --color-white-primary: #ffffff;

  --color-black-transparent-25: rgba(0, 0, 0, 0.25);

  --grey-0:  #555c61;
  --grey-1:  #6E7072;
  --grey-2:  #969C9E;
  --grey-3:  #C6CBCD;
  --grey-4:  #D3D7DB;
  --grey-5:  #DFE2E5;
  --grey-6:  #EBEEF1;
  --grey-7:  #F3F5F6;

  --color-green-primary: #79BE70;
  --color-green-light:   #99D899;
  --color-green-dark:    #6CB56C;
  --color-green-3:       #D5EDD5;
  --color-green-4:       #E4F0E4;

  --color-red-primary: #E26565;
  --color-red-1:  #d55050;
  --color-red-2:  #ca3737;
  --color-red-bg: #ffbbbb;

  --color-blue-primary: #4972B5;
  --color-blue-1:  #4267a3;
  --color-blue-2:  #3a5d96;

  --primary-color: var(--color-green-primary);
  --primary-color-light: var(--color-green-light);
  --primary-color-dark: var(--color-green-dark);
  --primary-color-bg: var(--color-green-3);

  --success-color: var(--color-green-primary);
  --attention-color: rgb(255, 221, 108);
  --error-color: var(--color-red-primary);

  --border-radius-xxs: 4px;
  --border-radius-xs: 6px;
  --border-radius-s: 8px;
  --border-radius-m: 12px;
  --border-radius-l: 16px;
  --border-radius-xl: 20px;
  --border-radius-xxl: 36px;

  --box-shadow-a: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 6px 12px rgba(0, 0, 0, 0.08);
  --box-shadow-b: 0px 0px 15px rgba(0, 0, 0, 0.1);
  --box-shadow-c: 0px 3px 6px rgba(105, 130, 166, 0.02), 0px 0px 2px rgba(47, 58, 74, 0.02);
  --box-shadow-d: 0px 0px 2px rgba(0, 0, 0, 0.02), 0px 3px 6px rgba(105, 130, 166, 0.02);
  --box-shadow-e: 0px 0px 20px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(0, 0, 0, 0.1);
  --box-shadow-f: 0px 5px 25px 0px rgba(0, 0, 0, 0.05);
  --box-shadow-m: 0px 16px 10px -10px rgba(121, 190, 112, 0.2);

  --box-shadow-blue: 0px 0px 23px rgba(44, 166, 216, 0.33), 0px 6px 12px rgba(29, 149, 209, 0.08);
  --box-shadow-green: 0px 0px 20px #D5EDD5, 0px 6px 12px #D5EDD5;

  --transition-fast: .1s ease;
  --transition: .3s ease;
  --transition-slow: .6s ease;

  --main-font: 'Inter', sans-serif;
  --headings-font: 'Montserrat', sans-serif;

  --body-color: var(--color-black-primary);
  --body-bg: var(--grey-7);

  --container-max-width: 1216px;
  --container-padding: 20px;
  --menu-width: 0px;

  --input-height: 56px;
  --input-padding-y: 16px;
  --input-padding-x: 16px;
  --input-font-size: 1rem;
  --input-line-height: 1.25;
  --input-radius: var(--border-radius-l);
  --input-bg: var(--color-white-primary);
  --input-placeholder: var(--grey-2);
  --input-border: transparent;
  --input-hover-border: var(--grey-4);
  --input-focus-border: var(--primary-color);
  --input-icon-padding: 42px;
  --input-icon-size: 24px;

  --textarea-height: 132px;

  --select-dropdown-item-padding: 10px 16px;
}

@media (max-width: 1280px) {
  :root {
    --container-max-width: 964px;
  }
}

@media (max-width: 1024px) {
  :root {
    --container-max-width: 756px;
  }
}

@media (max-width: 800px) {
  :root {
    --container-max-width: 600px;
  }
}

@media (max-width: 575px) {
  :root {
    --container-padding: 15px;

    --input-height: 46px;
    --input-padding-y: 12px;
    --input-padding-x: 12px;
    --input-font-size: 13px;
    --input-radius: var(--border-radius-m);
  }
}

* {
  box-sizing: border-box;
  outline: none;
}

::-webkit-input-placeholder,
::placeholder {
  color: var(--input-placeholder);
}

html,
body,
#root {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
}

html {
  zoom: 1 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
  color: var(--body-color);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  color: var(--color-black-primary);
  text-decoration: none;
  transition: var(--transition);
}

a:hover,
a:focus {
  text-decoration: none;
}

input,
select,
textarea,
.content-editable {
  padding: var(--input-padding-y) var(--input-padding-x);
  border-radius: var(--input-radius);
  font-family: var(--main-font);
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  background: var(--input-bg);
  border: 1px solid var(--input-border);
  outline: none;
  transition: border var(--transition), border-radius var(--transition), box-shadow var(--transition);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

input {
  height: var(--input-height);
}

textarea {
  height: var(--textarea-height);
}

input:hover,
textarea:hover {
  border-color: var(--input-hover-border);
}

input:focus,
textarea:focus {
  border-color: var(--input-hover-border);
}

input.error,
textarea.error {
  border-color: var(--error-color);
}

input.success,
textarea.success {
  border-color: var(--success-color);
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  height: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headings-font);
}

b {
  font-weight: 600;
}

button {
  color: var(--color-black-primary);
  font-family: var(--main-font);
  transition: all var(--transition);
  cursor: pointer;
}

.accent-text {
  color: var(--primary-color);
}

.container {
  margin-left: calc(50% - (var(--container-max-width) / 2));
  margin-right: calc(50% - (var(--container-max-width) / 2));
  width: 100%;
  max-width: var(--container-max-width);
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

.container.wide {
  max-width: 100%;
}

.skeleton-loader {
  position: relative;
  background: var(--grey-6);
  overflow: hidden;
}

.skeleton-loader::after {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    110deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 0)
  );
  transform: translateX(-100%);
  animation: shimmer 2s infinite;
  content: '';
}

.styled-scrollbar {
  scrollbar-width: 5px;
  scrollbar-color: var(--primary-color-light) transparent;
}

.styled-scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}

.styled-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background: var(--primary-color-light);
  outline: none;
  border-radius: 5px;
}

.options-content {
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-b);
  border-radius: var(--border-radius-m);
  overflow-y: auto;
}

.options-content::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.options-content::-webkit-scrollbar-track {
  background: transparent;
}

.options-content::-webkit-scrollbar-thumb {
  background: var(--primary-color-light);
  outline: none;
  border-radius: 5px;
}

div.forma-ui__tabs__header::after {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 800px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }

  html,
  body,
  #root {
    min-height: 100dvh;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}