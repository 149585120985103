.children {
  margin-top: 10px;
  margin-left: 6%;
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  background: var(--color-white-primary);
  border: 1px solid var(--grey-3);
  border-radius: var(--border-radius-l);
  user-select: none;
  cursor: pointer;
  transition: box-shadow var(--transition) .1s;
}

.root.open .arrowIcon {
  color: var(--primary-color);
  transform: rotate(90deg);
}

.root.selected {
  background: var(--color-green-4);
}

.root.moveHover {
  border: 1px dashed var(--primary-color);
  background: var(--color-green-4);
}

.root.loading {
  height: 72px;
  margin-bottom: 10px;
}

.arrowButton {
  position: absolute;
  top: 3px;
  left: 3px;
  bottom: 3px;
  flex-shrink: 0;
  padding: 1px;
  background: var(--grey-7);
  border-radius: var(--border-radius-l);
  text-align: center;
}

.arrowIcon {
  display: inline-block;
  margin-top: 9px;
  line-height: 0;
  transition: color var(--transition), transform var(--transition);
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 1px;
  border-radius: var(--border-radius-l);
  background-color: var(--grey-3);
  font-size: 0.75rem;
  line-height: 1.25;
}

.content {
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 75px;
  gap: 10px;
}

.icon {
  flex-shrink: 0;
  height: 30px;
  pointer-events: none;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.1rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.21;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameEmpty {
  margin-left: 10px;
  color: var(--grey-3);
}

.buttons {
  display: flex;
  align-items: center;
  padding: 0 22px;
  gap: 44px;
}

.optionsButton {
  flex-shrink: 0;
  line-height: 0;
}

.fave {
  line-height: 0;
}

.approved {
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.21;
  color: var(--primary-color);
}

@media (max-width: 1024px) {
  .content {
    padding-left: 48px;
  }

  .buttons {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (max-width: 575px) {
  .count {
    width: 18px;
    height: 18px;
  }

  .arrowIcon {
    margin-top: 7px;
  }

  .arrowIcon svg {
    width: 18px;
    height: 18px;
  }

  .root.loading {
    height: 60px;
  }
}
