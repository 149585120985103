.calendarHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
}

.calendar {
  max-width: 386px;
  margin: 0 auto;
}

.calendar :global(.react-datepicker__day-name),
.calendar :global(.react-datepicker__day),
.calendar :global(.react-datepicker__time-name) {
  width: 48px;
  height: 36px;
  line-height: 36px;
}

.time {
  width: 402px;
  min-height: 250px;
  margin: 0 auto;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-b);
  padding: 10px;
}

.buttonBack.buttonBack > span {
  margin: 0;
}

.timeItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
}

.arrow {
  color: var(--grey-2);
}