.modalSuccess h2 {
  text-align: center;
  font-size: 1.75rem;
  margin: 8px 0 0;
}

@media (min-width: 576px) {
  .modalButtons {
    justify-content: center;
  }

  .modalButtons > * {
    width: calc(50% - 10px);
  }
}