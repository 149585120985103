.columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  gap: 7px;
}

.fiveCols {
  grid-template-columns: repeat(5, 1fr);
}
