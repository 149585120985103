.buttons {
  width: 100%;
  max-width: 503px;
  margin-left: auto;
  margin-right: auto;
}

.buttons > * {
  flex: 1;
}
