.row {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 10px;
}

.value {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.28
}

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
}

.success {
  color: var(--success-color);
}
