.group {
  padding-left: 4%;
}

.groupItems {
  justify-content: center;
}

.dropContainer {
  position: absolute;
  inset: 0;
}

.groupLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 53px 0;
  color: var(--color-white-primary);
}

.modalMove {
  width: 500px;
}

.loader {
  padding: 16px;
  color: var(--color-white-primary);
}

.noItems {
  position: relative;
  margin: 0 -28px;
  padding: 14px 28px;
  background: var(--color-green-3);
  border-radius: var(--border-radius-l);
  grid-column: span 5;
}

@media (max-width: 1366px) {
  .noItems {
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
