.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.id {
  width: 30%;
  cursor: pointer;
}

.name {
  width: 48%;
  cursor: pointer;
}

.buttons {
  width: 200px;
  margin-top: -10px;
  margin-bottom: -10px;
  text-align: right;
}

.nameArrow {
  display: inline-block;
  line-height: 0;
  margin-left: 10px;
  transition: all var(--transition);
}

.nameArrow.open {
  transform: scaleY(-1);
}

.subitems:hover {
  box-shadow: none;
}

.subitemsCell.subitemsCell.subitemsCell {
  padding: 0 0 0 6%;
  border: none;
  background: none;
}

.subitems td {
  padding: 6px 16px;
  font-size: 0.9rem;
}

.file {
  min-height: 54px;
  width: 40%;
  cursor: pointer;
}

.descr {
  width: 30%;
  cursor: pointer;
}

.format {
  width: 10%;
  overflow: hidden;
  cursor: pointer;
}

.date {
  cursor: pointer;
}

.checkmark {
  display: inline-block;
  padding: 8.5px;
  line-height: 0;
}

.itemButtons {
  text-align: right;
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
