.items {
  display: flex;
  gap: 18px;
}

.item {
  flex: 1;
  min-height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 13px 15px;
  background: var(--grey-6);
  border-radius: var(--border-radius-l);
  text-align: center;
  gap: 16px;
}

.itemLabel {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1.42;
}

.itemValue {
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .items {
    flex-wrap: wrap;
  }

  .item {
    width: calc(50% - 9px);
    flex-direction: row;
    min-height: 0;
    gap: 6px;
  }

  .itemLabel {
    justify-content: flex-start;
    margin-right: auto;
    text-align: left;
  }
}

@media (max-width: 575px) {
  .items {
    gap: 8px;
  }

  .item {
    flex-basis: 100%;
    width: 100%;
    padding: 4px 14px;
  }
}
