.checkedIcon {
  margin-left: auto;
  color: var(--color-green-primary);
}

.buttonSettings {
  margin: 0 -10px;
  padding: 16px 10px;
}
.buttonIcon {
  width: 28px;
  height: 28px;
}

.signAsUser {
  border-top: 1px solid var(--grey-3);
}

.infoRow {
  font-size: 14px;
  color: var(--grey-0);
}

.infoTitle {
  margin-top: 16px;
  font-weight: 600;
}

.infoInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}

.infoInput {
  width: 100px;
  --input-height: 36px;
  --input-radius: var(--border-radius-s);
}

@media (min-width: 576px) {
  .modalContent {
    width: 500px;
  }
}
