.root {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px 8px;
}

.item {
  text-align: center;
}

.itemTitle {
  display: inline-block;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
  border-radius: var(--border-radius-l);
  background: var(--grey-3, #C6CBCD);
}

.itemValue {
  margin-top: 6px;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.28;
}

@media (max-width: 1024px) {
  .root {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 800px) {
  .root {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 575px) {
  .root {
    grid-template-columns: repeat(2, 1fr);
  }
}