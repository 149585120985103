.content {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.column.shrink {
  width: calc(40% - 9px);
}

.column.wide {
  width: calc(60% - 9px);
}

@media (max-width: 800px) {
  .content {
    flex-direction: column;
  }

  .column.column {
    width: 100%;
  }
}