.emptyDescription {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}

@media (min-width: 801px) {
  .fillingModal {
    width: 700px;
  }
}

@media (min-width: 1025px) {
  .fillingModal {
    width: 890px;
  }
}

@media (min-width: 1281px) {
  .fillingModal {
    width: 960px;
  }
}
