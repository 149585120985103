.header {
  display: flex;
  gap: 18px;
}

.button {
  min-width: 183px;
  justify-content: flex-start;
  text-align: left;
  background: var(--grey-6);
  box-shadow: var(--box-shadow-d);
}

.button.active {
  background: var(--color-green-4);
  box-shadow: var(--box-shadow-d);
}

.button:hover {
  background: var(--grey-5);
}

.button.active:hover {
  background: var(--color-green-4);
}

.buttonChart {
  padding-left: 18px;
  padding-right: 18px;
}

.buttonChart:hover {
  background: var(--grey-5);
}

.buttonChart:focus {
  background: var(--color-white-primary);
}

.content {
  margin-top: 25px;
}

@media (max-width: 1024px) {
  .header {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .header {
    gap: 8px;
  }
}