.item:not(:last-child) {
  margin-bottom: 12px;
}

.itemAdd {
  margin-top: 32px;
}

.button {
  position: relative;
  width: 100%;
}

.buttonAdd {
  width: 100%;
}

.title {
  margin-bottom: 26px;
  font-size: 24px;
  font-weight: 700;
}

.delete {
  position: absolute;
  top: 11px;
  right: 14px;
}

@media (min-width: 1025px) {
  .input {
    width: 500px;
  }
}
