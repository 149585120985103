.list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.item {
  position: relative;
  height: 165px;
  padding: 18px;
  width: calc(20% - 19.2px);
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  text-align: center;
  transition: box-shadow var(--transition) .1s;
  user-select: none;
  cursor: pointer;
}

.iconContainer {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  pointer-events: none;
}

.icon {
  height: 100%;
}

.name {
  margin-top: 18px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  display: -webkit-box;
  font-size: .875rem;
  height: 2.1rem;
  line-height: 1.21;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.status {
  position: absolute;
  left: 13px;
  bottom: 10px;
  font-size: 14px;
  line-height: 1.14;
  color: var(--grey-2);
  text-align: left;
}

.status.success {
  color: var(--color-green-primary);
}

.optionsButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background: none;
  border: none;
  box-shadow: none;
  color: var(--grey-7);
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  line-height: 0;
  padding: 0;
  transition: all var(--transition);
}

.optionsButton:hover {
  color: var(--grey-6);
}

@media (max-width: 1024px) {
  .item {
    width: calc(25% - 18.75px);
  }
}

@media (max-width: 800px) {
  .list {
    gap: 16px;
  }

  .item {
    width: calc(33% - 10.66px);
  }
}

@media (max-width: 575px) {
  .list {
    gap: 12px;
  }

  .item {
    width: calc(50% - 6px);
  }
}
