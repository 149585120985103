.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  font-size: 0.875rem;
  line-height: 1;
  background: #C6CBCD;
}