.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.id {
  width: 20%;
  cursor: pointer;
}

.lang {
  width: 5%;
  cursor: pointer;
}

.value {
  position: relative;
  width: 65%;
  cursor: pointer;
}

.button {
  width: 18px;
}

.remove:hover {
  color: var(--color-red-primary);
}

.value:hover .save {
  display: inline-flex;
}

.save {
  display: none;
  position: absolute;
  right: 11px;
  top: 15px;
  padding: 10px;
  background: var(--color-white-primary);
  z-index: 1;
}

.save svg {
  width: 20px;
  height: 20px;
  line-height: 0;
}

.save:hover,
.save:focus {
  color: var(--color-green-dark);
  background: var(--color-white-primary);
}

.input {
  padding-right: 50px;
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
