.icon {
  display: block;
  text-align: center;
  line-height: 0;
}

.icon svg {
  width: 46px;
  height: 46px;
  animation: loading-circle 0.75s linear infinite;
  animation-duration: 0.75s;
}

@media (max-width: 575px) {
  .icon {
    width: 32px;
  }

  .icon svg {
    width: 32px;
    height: 32px;
  }
}

@keyframes loading-circle {
  100% {
    transform: rotate(360deg);
  }
}
