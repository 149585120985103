.content {
  display: flex;
  gap: 20px;
}

.previewTitle {
  margin-bottom: 8px;
}

.fields {
  width: 430px;
  max-width: 430px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 10px;
  padding: 0 10px 40px;
}

.fields textarea {
  text-align: left;
}

.buttons {
  margin-top: 0;
}

.buttons > * {
  flex: 1;
}
