.root {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 12px;
  font-size: 1.25rem;
  font-weight: 700;
}

.add {
  height: 72px;
  color: var(--grey-2);
}

.add:hover {
  background: var(--grey-6);
  color: var(--grey-1);
}

.addIcon {
  width: 36px;
  height: 36px;
}

.slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
}

.remove {
  position: absolute;
  top: 6px;
  right: 6px;
}

.remove:hover {
  color: var(--color-red-primary);
}

.removeIcon {
  width: 18px;
  height: 22px;
}

.download {
  position: absolute;
  top: 4px;
  right: 34px;
}

@media (min-width: 801px) {
  .dropzone {
    width: 700px;
  }
}

@media (max-width: 800px) {
  .download,
  .remove {
    padding: 0;
  }
}
