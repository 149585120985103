.root {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-bottom: 10px;
  font-size: 14px;
}

.columnTarget {
  width: 120px;
}

.target {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  gap: 5px;
}

.targetInput {
  height: 32px;
}

.targetSave {
  margin-top: 5px;
  padding: 0;
  color: var(--primary-color);
  background: none;
  font-size: 14px;
  font-weight: 400;
}

.items {
  display: flex;
  gap: 10px;
}

.itemValue {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.itemLabel {
  margin-top: 5px;
  color: var(--grey-2, #969C9E);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
}

@media (max-width: 575px) {
  .items {
    flex-direction: column;
  }
}
