.fields {
  display: grid;
  margin-bottom: 60px;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 25px;
}

.fields > div:nth-child(1) {
  grid-column: 1/3;
}

.fields > div:nth-child(2) {
  grid-column: 1/2;
}

.inputContainer {
  position: relative;
}

.label {
  position: absolute;
  top: 18px;
  left: 18px;
  font-size: 0.75rem;
  z-index: 1;
}

.input {
  height: 84px;
  padding: 42px 18px 18px;
  font-weight: 500;
  border-radius: var(--border-radius-l);
}

.select :global(input) {
  height: auto;
}

.select :global(div.select__control) {
  height: 84px;
  line-height: 1.25;
  font-weight: 500;
}

.select :global(div.select__value-container) {
  padding: 42px 18px 18px;
}

.buttons {
  margin-top: 40px;
  gap: 20px 30px;
}

.buttons > * {
  flex: 1;
}

.passwordForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.passwordForm .input {
  width: 490px;
  background: var(--body-bg);
}

.title {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 1.5rem;
}

.buttonInvite {
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;
}

.inviteSuccess {
  margin-top: 150px;
  text-align: center;
}

.inviteSuccessText {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 1.25rem;
  font-weight: 500;
}

.inviteSuccessButtons {
  justify-content: center;
}

.inviteSuccessButton {
  min-width: 330px;
}

@media (max-width: 800px) {
  .fields {
    grid-template-columns: repeat(2, 1fr);
  }

  .passwordForm .input {
    width: 100%;
  }

  .inviteSuccess {
    margin-top: 70px;
  }

  .input {
    height: 64px;
    padding: 28px 14px 12px;
  }

  .label {
    top: 14px;
    left: 14px;
  }
}

@media (max-width: 575px) {
  .fields {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .inviteSuccess {
    margin-top: 0;
  }

  .inviteSuccessButton {
    min-width: 0;
  }

  .fields > div:nth-child(1) {
    grid-column: 1;
  }

  .fields > div:nth-child(2) {
    grid-column: 1;
  }
}
