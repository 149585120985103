.title {
  margin-bottom: 18px;
}

.link {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background: none;
  border: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  gap: 10px;
}

.linkIcon {
  line-height: 0;
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
}

.item {
  padding: 10px 12px 17px;
  background: var(--grey-6);
  border-radius: var(--border-radius-l);
  cursor: pointer;
}

.itemHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.itemStatus {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--grey-2);
  font-size: 14px;
  line-height: 1;
}

.itemStatusIcon {
  color: var(--grey-3);
  line-height: 0;
}

.itemStatus.success,
.itemStatus.success .itemStatusIcon {
  color: var(--color-green-primary);
}

.itemDownloadIcon {
  line-height: 0;
}

.itemDownloadIcon svg {
  width: 16px;
  height: 16px;
}

.itemName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 32px;
  font-size: 14px;
  line-height: 1.14;
  text-align: center;
}

.itemImage {
  margin: 11px 0 11px;
  line-height: 0;
  text-align: center;
}

.itemIcon {
  width: 40px;
  height: 40px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}