.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 88px;
  padding: 12px 13px;
  gap: 12px;
}

.logo {
  position: relative;
  z-index: 100;
}

.buttons {
  display: flex;
  gap: 32px;
  margin-left: auto;
}

.buttons a,
.buttons button {
  white-space: nowrap;
}

.content:global(.container) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 584px;
  margin: 64px auto;
}

.content::after {
  content: '';
  display: block;
  height: 100px;
}

@media (max-width: 575px) {
  .buttons {
    gap: 16px;
  }

  .buttons a,
  .buttons button {
    flex: 1;
  }

  .textButton {
    display: none;
  }

  .content:global(.container) {
    margin: 32px auto;
  }

  .content::after {
    height: 20px;
  }
}
