.row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  font-size: 14px;
  line-height: 1.4;
  gap: 10px;
}

.row:not(:last-child) {
  margin-bottom: 5px;
}

.value {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  gap: 6px;
}

.valueLarge {
  font-size: 16px;
}

.usersIcon {
  display: inline-block;
  margin: -8px -6px;
  line-height: 0;
  vertical-align: middle;
}

.gray {
  color: var(--grey-2);
}

.valueButton {
  margin-top: -1px;
}

.editButtonIcon {
  width: 20px;
  height: 20px;
}

@media (max-width: 800px) {
  .copyButton {
    padding: 0;
  }
}
