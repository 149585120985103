
.search {
  position: relative;
}

.search.active .searchInput {
  background: var(--color-white-primary);
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  line-height: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.searchInput {
  height: 56px;
  width: 100%;
  padding-left: 44px;
  padding-right: 16px;
  background: none;
  border-color: var(--grey-3);
}

.searchInput:hover {
  border-color: var(--grey-2);
}

.searchInput:focus {
  border-color: var(--primary-color);
}
