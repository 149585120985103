.root {
  display: flex;
  align-items: flex-start;
}

.content {
  flex: 0 0 21cm;
  max-width: 21cm;
}

.side {
  position: sticky;
  top: 20px;
  flex: 1 0;
  margin-left: 25px;
  max-height: calc(100vh - 20px - 40px);
}

.modalDescription {
  text-align: center;
}

@media (max-width: 1280px) {
  .root {
    overflow-x: scroll;
  }

  .side {
    position: static;
    flex: 0 0 300px;
  }
}
