.root {
  background: var(--grey-7);
  box-shadow: var(--box-shadow-a);
  border-radius: var(--border-radius-l);
}

.header {
  display: flex;
  align-items: flex-start;
  padding: 11px 15px;
  cursor: pointer;
  gap: 16px;
}

.title {
  padding-left: 26px;
  flex-grow: 1;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
}

.arrow {
  flex-shrink: 0;
  transition: transform var(--transition);
}

.arrow.open {
  transform: scaleY(-1);
}

.content {
  padding: 5px 13px 17px;
}

.root.white {
  background: var(--color-white-primary);
}

.root.medium .header {
  padding-top: 7px;
  padding-bottom: 7px;
}

.root.medium .title {
  padding-left: 0;
  text-align: left;
}
