.root {
  height: 100%;
  padding: 25px;
  border-radius: var(--border-radius-l);
  border: 1px solid var(--grey-3);
}

.title {
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 600;
}
