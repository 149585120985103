.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.item {
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 25px;
  border-radius: var(--border-radius-s);
  border: 1px dashed var(--grey-3);
  gap: 20px;
  cursor: pointer;
}

.item.active {
  background: var(--color-green-4);
  border-color: var(--color-green-4);
}

.item.active .checkbox {
  color: var(--color-green-primary);
}

.left {
  flex-grow: 1;
}

.right {
  flex-shrink: 0;
}

.title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}

.name {
  margin-top: 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--grey-2);
}

.info {
  margin-top: 7px;
  font-size: 12px;
  line-height: 1.25;
  color: var(--grey-2);
}

.checkbox {
  color: var(--grey-3);
}

@media (min-width: 1025px) {
  .items {
    width: 760px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (min-width: 801px) {
  .items {
    width: 700px;
  }
}

@media (max-width: 800px) {
  .items {
    grid-template-columns: 1fr;
  }
}
