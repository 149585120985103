.root {
  display: block;
  position: relative;
}

.modalMove {
  width: 500px;
}

@media (max-width: 800px) {
  .modalMove {
    width: 100%;
  }
}
