.items {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
}

.row {
  display: flex;
  align-items: center;
}

.icon {
  flex-shrink: 0;
}

.content {
  margin-left: 18px;
  padding: 15px 18px;
  border-left: 2px solid var(--grey-3);
  font-size: 0.875rem;
}

.name {
  font-weight: 600;
}

.link {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background: none;
  border: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  gap: 10px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

@media (max-width: 800px) {
  .items {
    gap: 16px;
  }

  .icon {
    width: 42px;
    height: 42px;
  }

  .content {
    padding: 4px 12px;
    margin-left: 12px;
  }
}
