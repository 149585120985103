.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.score {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border-radius: var(--border-radius-m);
  color: var(--color-white-primary);
}

.lastDayDiffHead {
  text-align: center;
}

.date {
  width: 25%;
  font-size: 14px;
}

.count {
  width: 25%;
  font-size: 14px;
}

.averageScore {
  width: 25%;
  padding-left: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.lastDayDiff {
  width: 25%;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  text-align: right;
}

.lastDayDiff > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
