.root {
  margin-bottom: 54px;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.search.active::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -6px;
  height: 100px;
  border-radius: var(--border-radius-l);
  background: var(--color-green-3);
}

.search.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: -28px;
  width: 24px;
  height: 24px;
  background: no-repeat url('./out_radius.svg');
  background-size: 24px 24px;
}

.container button,
.container a {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  white-space: nowrap;
}

.icon.active path {
  fill: var(--color-white-primary);
}

.searchResults {
  margin: 8px -8px -22px;
  padding: 16px 8px 22px;
  background: var(--color-green-3);
  border-radius: var(--border-radius-l);
}

.searchNoItems {
  padding: 36px 28px;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.searchResults .loader {
  padding: 28px 0;
}

.item {
  height: 165px;
}

.viewSwitch {
  flex-shrink: 0;
  max-width: 100%;
  overflow: hidden;
  transition: max-width var(--transition);
}

.viewSwitch.hidden {
  max-width: 0;
}

@media (max-width: 1024px) {
  .container {
    flex-wrap: wrap;
  }

  .container > div {
    flex-grow: 1;
  }

  .search {
    width: 100%;
  }

  .search.active::before {
    left: -8px;
    right: -8px;
  }

  .search.active::after {
    content: none;
  }

  .searchResults {
    position: absolute;
    top: 64px;
    left: -8px;
    right: -8px;
    margin: 0;
    z-index: 1;
  }

  .list {
    gap: 20px;
  }
}

@media (max-width: 575px) {
  .container {
    flex-direction: column;
  }

  .container > div {
    width: 100%;
  }
}
