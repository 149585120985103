.items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 22px;
}

.buttons {
  justify-content: center;
  margin: 30px auto 0;
}

.button {
  flex: 0 0 25%;
}

.notice {
  display: flex;
  align-items: center;
  margin: 35px 0;
  color: var(--grey-1);
  text-align: center;
  gap: 10px;
}

.noticeContent {
  flex-grow: 1;
}

.noticeButtons {
  flex-shrink: 0;
  width: 25%;
}

.noticeButtons:first-child {
  text-align: left;
}
.noticeButtons:last-child {
  text-align: right;
}

.noticeButton {
  position: relative;
}

.noticeBage {
  position: absolute;
  top: -10px;
  right: 9px;
  min-width: 46px;
  border-radius: var(--border-radius-s);
  font-size: .75rem;
  line-height: 1.67;
  font-weight: 400;
  color: var(--color-white-primary);
}

.noticeBage.error {
  background: var(--error-color);
}

.noticeBage.success {
  background: var(--success-color);
}

.emptyFields {
  margin-bottom: 5px;
  color: var(--error-color);
  font-size: 1.125rem;
}

.root :global(.forma-ui__fill-products-table__tableWrapper) {
  padding-bottom: 45px;
  margin-bottom: -45px;
}

@media (max-width: 1366px) {
  .button {
    flex: 0 0 31%;
  }
}

@media (max-width: 800px) {
  .button {
    flex: 0 0 45%;
  }

  .notice {
    display: none;
  }
}

@media (max-width: 575px) {
  .buttons {
    flex-direction: column;
  }

  .bage.error {
    color: var(--error-color);
  }

  .bage.success {
    color: var(--success-color);
  }
}
