.value {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.28;
}

.text {
  margin-top: 5px;
  color: var(--color-green-primary);
  font-size: 14px;
  line-height: 1.42
}
