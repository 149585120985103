.root {
  display: flex;
  gap: 25px;
}

.column {
  flex: 1;
}

.column:not(:last-child) {
  padding-right: 25px;
  border-right: 1px solid var(--grey-3, #C6CBCD);
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.subtitle {
  margin-top: 10px;
  color: var(--green-1, #79BE70);
  font-size: 14px;
  line-height: 1.42;
}

.item {
  display: flex;
  align-items: center;
  margin-top: 22px;
  font-size: 14px;
  line-height: 1.42;
  gap: 10px;
}

.itemValue {
  color: var(--grey-2, #969C9E);
}

@media (max-width: 800px) {
  .root {
    flex-direction: column;
  }

  .column:not(:last-child) {
    padding-right: 0;
    padding-bottom: 25px;
    border-right: 0;
    border-bottom: 1px solid var(--grey-3, #C6CBCD);
  }
}
