.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 88px;
  padding: 12px 13px;
  transition: var(--transition);
}

.content {
  position: relative;
  margin-top: 24px;
  margin-bottom: 40px;
  flex-grow: 1;
  transition: var(--transition);
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}

.buttons a,
.buttons button {
  white-space: nowrap;
}

.title {
  font-family: var(--headings-font);
  font-size: 1.125rem;
  font-weight: 700;
}

.langButton {
  width: 42px;
  height: 42px;
  padding: 4px;
  border-radius: var(--border-radius-xs);
}

@media (min-width: 1281px) and (max-width: 1759px) {
  header.menuOpen,
  div.menuOpen {
    width: calc(100% - 280px);
    margin-left: 280px;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  header.header,
  div.content {
    padding-left: 80px;
  }
}

@media (min-width: 576px) and (max-width: 1280px) {
  header.header,
  div.content {
    max-width: 100%;
    width: calc(100% - 80px);
    margin-left: 80px;
  }
}

@media (max-width: 575px) {
  .header {
    flex-wrap: wrap;
  }

  .nav {
    margin-left: 0;
  }

  .menu {
    margin: 10px 12px auto 0;
  }
}
