.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 44px;
  height: 44px;
  border: none;
  box-shadow: var(--box-shadow-a);
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.line {
  display: block;
  width: 22px;
  height: 2px;
  margin: 2px;
  border-radius: var(--border-radius-l);
  background: var(--color-black-primary);
  transition: transform var(--transition), opacity var(--transition-fast);
}

/* .active .line:nth-child(1) {
  transform: rotate(45deg) translate(6px, 5px);
}

.active .line:nth-child(2) {
  opacity: 0;
}

.active .line:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -5px);
} */
