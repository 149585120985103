.modalContent input {
  width: 100%;
  border-color: var(--grey-4);
}

@media (min-width: 801px) {
  .modalContent {
    width: 500px;
    margin: 0 0 30px;
  }
}
