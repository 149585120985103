.row {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 15px;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.addIcon.addIcon {
  color: var(--primary-color);
}

.switcher {
  margin-left: auto;
  padding: 0;
  flex: 0 0 260px;
}

.switcher > label {
  flex-direction: row-reverse;
}

@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
  }

  .root button,
  .root a {
    flex: 1;
  }

  .root > div {
    flex-grow: 1;
  }

  .search {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .root button,
  .root a {
    width: 100%;
  }
}
