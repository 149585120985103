.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.id {
  width: 10%;
  font-weight: 500;
}

.name {
  width: 28%;
}

.type {
  width: 20%;
}

.value {
  width: 15%;
}


.date {
  width: 20%;
}

.id,
.name,
.type,
.value,
.date {
  font-size: 14px;
  cursor: pointer;
}

.buttons {
  width: 90px;
}

.buttonsContainer {
  display: flex;
  gap: 16px;
}

.buttonsContainer button {
  line-height: 0;
}

.addButton {
  margin-left: auto;
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
