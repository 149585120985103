.root {
  display: flex;
  gap: 25px;
}

.column:not(:last-child) {
  padding-right: 25px;
  border-right: 1px solid var(--grey-3, #C6CBCD);
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 0;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.items {
  margin-top: 18px;
}

.item {
  display: flex;
  align-items: center;
  min-height: 34px;
  padding: 5px 25px;
  margin: 0 -25px;
  font-size: 14px;
  line-height: 1.42;
}

.item {
  background: var(--grey-6);
}

.item:nth-child(2n) {
  background: var(--grey-7);
}

.registers {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
  gap: 5px;
}

.payments {
  display: flex;
  align-items: center;
}

.payments b {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid var(--grey-3, #C6CBCD);
}

.conversion {
  display: flex;
  align-items: center;
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .column {
    padding-right: 25px;
    width: calc(100% + 25px) !important;
    border-right: 0 !important;
  }
}