.fields {
  min-width: 680px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 10px;
  padding: 0 10px 40px;
}

.buttons {
  margin-top: 0;
}

.buttons > * {
  flex: 1;
}