.item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemValue {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.28;
}

.itemText {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
  gap: 5px;
}

.itemSimple {
  display: flex;
  align-items: center;
  gap: 5px;
}

.itemValueSimple {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.28;
}

.itemTextSimple {
  font-size: 14px;
  line-height: 1.42;
}

.success {
  color: var(--success-color);
}

.error {
  color: var(--error-color);
}

.row {
  display: flex;
  gap: 10px;
}

.text {
  margin-top: 18px;
  margin-bottom: 10px;
}
