.modalButtons {
  flex-direction: column;
  align-items: stretch;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 801px) {
  .modalContent {
    width: 560px;
  }
}
