.root {
  display: flex;
}

.item {
  cursor: pointer;
  border-radius: 52px;
}

.item:not(:last-child) {
  margin-right: -12px;
}

.item:hover {
  z-index: 20 !important;
}

.item.active {
  box-shadow: 0 0 0 2px #79BE70;
  z-index: 15 !important;
}

.icon {
  flex-shrink: 0;
  width: 52px;
  height: 52px;
  border: 2px solid var(--color-white-primary);
  font-size: 18px;
}
