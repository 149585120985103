.root {
  max-width: 630px;
}

.header {
  margin-bottom: 40px;
}

.group {
  display: flex;
  margin-bottom: 10px;
  border-radius: var(--border-radius-l);
  border: 1px solid var(--grey-3, #C6CBCD);
  background: var(--grey-7, #F3F5F6);
  font-size: 12px;
  line-height: 1.25;
}

.groupName {
  width: 185px;
  flex-shrink: 0;
  padding: 10px;
  border-right: 1px solid var(--grey-3, #C6CBCD);
  font-weight: 600;
}

.groupItems {
  flex-grow: 1;
}

.item {
  display: flex;
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--grey-3, #C6CBCD);
}

.itemName {
  padding: 10px;
  flex-grow: 1;
}

.itemValue {
  width: 66px;
  flex-shrink: 0;
  border-left: 1px solid var(--grey-3, #C6CBCD);
}

.input {
  width: calc(100% + 2px);
  margin: -1px;
  padding: 10px;
  height: 37px;
  border-radius: 0;
  border: 1px solid var(--primary-color);
}

.input:hover,
.input:focus {
  border: 1px solid var(--primary-color);
}

.item:first-child .input {
  border-top-right-radius: var(--border-radius-l);
}

.item:last-child .input {
  border-bottom-right-radius: var(--border-radius-l);
}
