.root {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  gap: 16px;
}

.left,
.right {
  display: flex;
  gap: 20px;
}

.select {
  min-width: 220px;
  max-width: 320px;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 10px;
}

.select:hover,
.select:focus {
  background: var(--color-white-primary);
}

.select.select > span {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectIcon {
  width: 12px;
}

.signIn {
  border-radius: var(--border-radius-l);
  background: var(--grey-6);
  line-height: 1;
}

.count {
  width: 60px;
  font-size: 20px;
  font-weight: 600;
  padding: 15px 5px;
  border-radius: var(--border-radius-l);
  color: var(--color-white-primary);
  /* background: #C6CBCD; */
  box-shadow: 0px 0px 2px 0px rgba(47, 58, 74, 0.02), 0px 3px 6px 0px rgba(105, 130, 166, 0.02);
  text-align: center;
}

@media (max-width: 800px) {
  .root {
    flex-direction: column;
  }

  .select {
    flex-grow: 1;
  }

  .right {
    justify-content: flex-end;
  }

  .count {
    width: 48px;
    padding: 9px 3px;
  }
}
