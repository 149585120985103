.modal {
  padding-left: 16px;
  padding-right: 16px;
}

.modalTitle {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
}

.modalTitleIcon {
  position: absolute;
  top: 0;
  left: 0;
}

.modalContent {
  display: flex;
  justify-content: center;
  gap: 46px;
}

.modalButtons {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.timeHead {
  margin-bottom: 20px;
  font-family: var(--headings-font);
  font-size: 14px;
}

.timeItems {
  width: 304px;
  min-height: 258px;
}

.modalButton {
  max-width: 276px;
  margin-left: auto;
  margin-right: auto;
}

.modal :global(.react-datepicker) {
  box-shadow: none;
}

.arrow {
  color: var(--grey-2);
}

@media (max-width: 800px) {
  .modal {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .timeItems {
    width: 230px;
  }
}

@media (max-width: 640px) {
  .modalContent {
    gap: 12px
  }
}

@media (max-width: 575px) {
  .modal {
    height: 65vh;
    height: 65dvh;
  }

  .modalContent {
    justify-content: center;
  }

  .modalButton {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .timeItems {
    width: 100%;
  }
}