.link {
  display: flex;
  align-items: center;
}

.image,
.imageText {
  flex-shrink: 0;
}

.image {
  width: 37px;
}

.imageText {
  width: 102px;
  margin-left: 13px;
}

.button {
  min-width: 211px;
  padding: 12px 16px;
  border-radius: var(--border-radius-l);
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-a);
}

.button:focus {
  outline: none;
}

@media (max-width: 575px) {
  .button {
    min-width: 160px;
    padding: 10px 12px;
  }

  .image {
    width: 32px;
  }

  .imageText {
    width: 80px;
  }
}