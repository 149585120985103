.wrapper {
  position: relative;
  height: 72px;
  margin-bottom: 10px;
}

.container {
  height: 100%;
}

.children {
  position: relative;
  top: 0;
  height: 100%;
  cursor: pointer;
  user-select: none;
  transition: top var(--transition) .1s;
}

.children::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.children.updated {
  animation: show 0.6s ease 0.6s;
  animation-iteration-count: 2;
}

.children.updated > div {
  box-shadow: var(--box-shadow-green);
}

.dropContainer {
  height: 100%;
}

:global(.onboarding-show) .children {
  top: 0;
}

@keyframes show {
  0% {
    top: 0;
  }

  50% {
    top: -12px;
  }

  100% {
    top: 0;
  }
}

@media (max-width: 575px) {
  .wrapper {
    height: 60px;
  }
}