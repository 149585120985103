.root {
  display: flex;
  gap: 28px;
}

.col {
  display: flex;
  flex-direction: column;
}

.col:not(:last-child) {
  padding-right: 28px;
  border-right: 1px solid var(--grey-3);
}

.col:nth-child(1),
.col:nth-child(3) {
  width: 12%;
}

.col:nth-child(4) {
  width: 23%;
}

.title {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}

.content {
  flex-grow: 1;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
}

.footerValue {
  text-align: right;
  color: var(--primary-color);
}

.dayRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  gap: 10px;
}

.dayValue {
  font-weight: 600;
  text-align: right;
}

.years {
  display: flex;
  flex-flow: column wrap;
  max-height: 210px;
  gap: 0 20px;
}

.select {
  display: inline-block;
  margin-left: 10px;
  min-width: 200px;
}

.select :global(div.select__value-container) {
  padding: 0 20px;
}

.select :global(div.select__control) {
  min-height: 30px;
  height: 30px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  border-radius: var(--border-radius-xs);
}

.select :global(div.select__dropdown-indicator) {
  padding: 0 10px;
}

.select :global(div.select__menu) {
  margin-top: 4px;
  border-radius: var(--border-radius-xs);
}

.select :global(div.select__option) {
  height: 32px;
}

@media (max-width: 1280px) {
  .select {
    min-width: 180px;
  }
}

@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
  }

  .col:nth-child(1),
  .col:nth-child(3) {
    width: 20%;
  }

  .col:nth-child(4) {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }

  .col.col {
    width: 100%;
    border-right: 0;
    padding-right: 0;
  }

  .title {
    font-size: 18px;
  }
}
