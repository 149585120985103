.root {
  display: flex;
  padding: 10px 10px 12px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  gap: 4px;
  transition: background var(--transition);
}

.root:hover {
  background: #F3F5F6;
}

.root.danger {
  background: #FFF3EF;
}

.root.error {
  background: #FFCDCD;
}

.labelWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 7px;
  gap: 4px;
}

.label {
  display: inline-block;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: var(--border-radius-xs);
  background: var(--grey-2);
  color: var(--color-white-primary);
}

.label.danger {
  background: #FFA88C;
}

.label.error {
  background: #E26565;
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.controls {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.title {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 600;
  gap: 5px;
}

.description {
  width: 100%;
  margin-top: 7px;
  font-size: 12px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  margin-top: 7px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.2;
}

.time.DURATION_GOOD {
  color: #79BE70;
}

.time.DURATION_NORMAL {
 color: #969C9E;
}

.time.DURATION_BAD {
  color: #FFA88C;
}

.time.DURATION_OVERDUE {
  color: #E26565;
}

.userIcon {
  width: 24px;
  height: 24px;
  font-size: 9px;
}

.userIcon img {
  width: 12px;
  height: 12px;
}

.status {
  text-align: center;
}

.user {
  margin-top: auto;
}

.option {
  display: flex;
  align-items: center;
  font-size: 15px;
  gap: 12px;
}

.optionIcon {
  flex-shrink: 0;
}
