.root {
  display: flex;
  gap: 36px;
}

.side {
  flex-shrink: 0;
  width: 220px;
}

.content {
  flex-grow: 1;
}
