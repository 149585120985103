.table td {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.id {
  width: 10%;
  cursor: pointer;
}

.letter {
  width: 20%;
  cursor: pointer;
}

.name {
  width: 65%;
  cursor: pointer;
}

.button {
  display: flex;
  width: 80px;
  gap: 16px;
}

.remove {
  margin-left: auto;
  line-height: 0;
}

.remove:hover {
  color: var(--color-red-primary);
}

.nameArrow {
  display: inline-block;
  line-height: 0;
  margin-left: 10px;
  transition: all var(--transition);
}

.nameArrow.open {
  transform: scaleY(-1);
}

.listItem {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 8px 16px;
  border-bottom: 1px solid var(--grey-3);
  gap: 28px;
  transition: all var(--transition);
  cursor: pointer;
}

.listItem:hover {
  background: var(--grey-6);
}

.dropContainer {
  transition: var(--transition-fast);
}

.dragging {
  background: var(--color-white-primary);
}

.loader {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subitems:hover {
  box-shadow: none;
}

.subitemsCell.subitemsCell.subitemsCell {
  padding: 0 0 0 6%;
  border: none;
  background: none;
}

@media (max-width: 800px) {
  .buttons > * {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .table td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
