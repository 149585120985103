.buttons {
  width: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

.remove:hover {
  color: var(--error-color);
}

.removeIcon {
  width: 18px;
  height: 22px;
}

.cellLink {
  padding-top: 0;
  padding-bottom: 0;
}

.cellLink a {
  display: flex;
  align-items: center;
  height: 44px;
}