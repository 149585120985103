.head {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search {
  flex-grow: 1;
}

.headButton {
  flex-shrink: 0;
}

.list {
  padding-inline-start: 25px;
}

.row > * {
  margin-left: 10px;
}

.row:not(:first-child) {
  margin-top: 15px;
}

.select {
  width: auto;
  min-width: 350px;
}

.select :global(.rc-select-selector) {
  background: var(--grey-6);
  height: 48px;
}

.select:global(.rc-select-open .rc-select-selector) {
  background: var(--color-white-primary);
}

.select :global(.rc-select-selection-placeholder) {
  color: var(--color-black-primary);
}

.option {
  display: flex;
  align-items: center;
  gap: 12px;
}

.forSelf {
  font-size: 12px;
  color: var(--grey-1);
}

.comment {
  margin-top: 15px;
  background: none;
  border-color: var(--grey-3);
}

.comment:hover {
  border-color: var(--grey-2);
}

.comment:focus {
  border-color: var(--primary-color);
}

.dropzone {
  height: 140px;
  padding: 15px;
  margin-top: 15px;
}

.search.active .searchInput {
  background: var(--color-white-primary);
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  line-height: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.searchSelect :global(.rc-select-selector) {
  border-color: var(--grey-3);
}

.searchInput:hover {
  border-color: var(--grey-2);
}

.searchInput:focus {
  border-color: var(--primary-color);
}

.submitButton {
  min-width: 238px;
}
