.root {
  display: flex;
  gap: 18px;
}

.right {
  flex-grow: 1;
}

.block {
  margin-bottom: 18px;
  padding: 18px 0;
  border-radius: var(--border-radius-l);
  border: 1px solid var(--grey-3);
}

.blockTitle {
  margin-bottom: 18px;
  padding: 0 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.row {
  display: flex;
  align-items: center;
  padding: 7px 18px;
  font-size: 14px;
  background: var(--grey-6);
  gap: 10px;
}

.row:nth-child(2n) {
  background: none;
}

.date {
  flex-shrink: 0;
  color: var(--grey-2);
  font-size: 12px;
}

.link {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background: none;
  border: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  gap: 10px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
  }
}