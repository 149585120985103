.root {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 12px;
  font-size: 1.25rem;
  font-weight: 700;
}

.add {
  height: 180px;
  color: var(--grey-2);
}

.add:hover {
  background: var(--grey-6);
  color: var(--grey-1);
}

.addIcon {
  width: 60px;
  height: 60px;
}

.previews {
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--body-bg);
  opacity: 0.5;
  z-index: 10;
}

.preview {
  position: relative;
  line-height: 0;
  border-radius: var(--border-radius-m);
  overflow: hidden;
  cursor: pointer;
}

.previewImage {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.slidePreview {
  position: relative;
  max-height: 87vh;
  overflow-y: auto;
}

.slidePreviewImage {
  width: 100%;
}

.remove {
  position: absolute;
  top: 10px;
  right: 10px;
}

.remove:hover {
  color: var(--color-red-primary);
}

.removeIcon {
  width: 18px;
  height: 22px;
}

@media (min-width: 801px) {
  .dropzone {
    width: 700px;
  }
}

@media (max-width: 1024px) {
  .previews {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 800px) {
  .previews {
    grid-template-columns: repeat(4, 1fr);
  }

  .remove {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .previews {
    grid-template-columns: repeat(2, 1fr);
  }

  .previewImage {
    height: 120px;
  }

  .add {
    height: 120px;
  }
}
